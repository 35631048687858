<template>
    <div class="page">
        <ThirdFloor width="100%" />
        
        <div class="grey--text">
            Tap on the stations (<v-icon small>mdi-map-marker</v-icon>) for more details
        </div>

        <v-row no-gutters>
            <v-col cols="4" class="px-1">
                <v-btn block depressed rounded dark color="grey darken-4" style="font-size: 9px;" to="/first-floor">
                    FIRST FLOOR 
                    <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>
            </v-col>
            <v-col cols="4" class="px-1">
                <v-btn block depressed rounded dark color="grey darken-4" style="font-size: 9px;" to="/second-floor">
                    SECOND FLOOR 
                    <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>
            </v-col>
            <v-col cols="4" class="px-1">
                <v-btn block depressed rounded dark color="grey darken-4" style="font-size: 9px;" to="/third-floor">
                    THIRD FLOOR 
                    <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>
            </v-col>
        </v-row>

        <v-dialog v-model="rooftopDialog">
            <v-card>
                <v-img src=""></v-img>
                <v-card-text>
                    <v-card-title class="px-0">Rooftop</v-card-title>
                    Sep into our newly renovated rooftop, overseeing the wonderful views of Santurce, Puerto Rico. 
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import ThirdFloor from '../assets/third-floor.svg?inline';
export default {
    data: () => ({
        rooftopDialog   : false
    }),
    mounted() {
        document.title = 'LFA | HOF - Third Floor';
        setTimeout(() => {
            this.init();
        }, 1000 );
    },
    methods: {
        init() {
            document.getElementById('rooftop1').addEventListener('click', () => {
                this.rooftopDialog = true;
            });
        }
    },
    components: { ThirdFloor }
}
</script>

<style lang="scss" scoped>
.page {
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: space-between;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
svg text {
    font-size: 6px;
}
</style>